import React from "react";
import Img from "gatsby-image";
import { useStaticQuery, graphql } from "gatsby"; // to query for image data

export default function CompanyLogo() {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "logo-tepanhaky-v2.png" }) {
        childImageSharp {
          fluid(maxWidth: 280) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `);
  return <Img className="logo-image" fluid={data.file.childImageSharp.fluid} />;
}
