import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&display=swap');

* {
	box-sizing: border-box;

	&:before,
	&:after {
		display: none;
		content: '';
	}
}

html,
body {
	height: auto;
	min-height: 100%;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0;
}

img {
	display: block;
	max-width: 100%;
}

video {
	pointer-events: none;
}

input,
textarea,
select {
	font-size: 100%;
	appearance: none;
}

textarea {
	resize: none;
}

ul,
ol,
figure {
	margin: 0;
	padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: "Inter", sans-serif;
}

p,
li,
small {
	font-family: "Inter", sans-serif;
}

li {
	list-style: none;
}

video {
	width: 100%;
}

table {
	border: none;
	border-collapse: collapse;
	width: 100%;
}

form {
	width: 100%;
	border: none;
}

fieldset {
	border: none;
	margin: 0;
	padding: 0;
}

input,
select,
textarea {
	&:focus {
		outline: none;
	}
}

`;

export default GlobalStyle;
