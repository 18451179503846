import React from "react";
import styled from "styled-components";
import media from "styled-media-query"; //https://github.com/morajabi/styled-media-query
import TextLoop from "react-text-loop";
import CompanyLogo from "./CompanyLogo";

const HeroWrapper = styled.div`
  background-color: #1b1d1c;
  background-image: url(/background.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  min-height: 480px;
  padding: 2rem 0;

  .logo-image {
    height: calc(200px * 0.6);
    width: calc(280px * 0.6);
    margin: 0 auto 3rem;

    ${media.greaterThan("medium")`
      margin-bottom: 5rem;
    `}
  }

  ${media.greaterThan("medium")`
    min-height: 550px;
    padding-bottom: 4rem;
  `}
`;

const Title = styled.h1`
  color: white;
  text-align: center;
  font-family: "Inter", sans-serif;
  font-size: 2.9rem;
  font-weight: 900;
  letter-spacing: 0.95px;
  max-width: 90%;
  margin: 0 auto;

  span {
    display: block;
    color: #ba252a;
    text-align: left;
    min-width: 90vw;
    text-align: center;
  }

  ${media.greaterThan("medium")`
    margin-top: 6.25rem;
    font-size: 5rem;
    letter-spacing: 0.75px;

    span{
      text-align:left;
      min-width: 330px;
    }

  `}
`;

function PageHero(props) {
  return (
    <HeroWrapper>
      {CompanyLogo()}
      <Title>
        O melhor{" "}
        <TextLoop interval="2000">
          <span>japa</span>
          <span>sushi</span>
          <span>yakisoba</span>
          <span>sashimi</span>
          <span>teppan</span>
        </TextLoop>{" "}
        da cidade.
      </Title>
    </HeroWrapper>
  );
}

export default PageHero;
