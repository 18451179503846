import styled from "styled-components";
import media from "styled-media-query"; //https://github.com/morajabi/styled-media-query

const SectionTitle = styled.h3`
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0.9375rem;
  font-size: 2.1875rem;
  color: #0e1428;
  text-align: center;

  ${media.greaterThan("medium")`
    font-size: 2.8125rem;
  `}
`;

export default SectionTitle;
