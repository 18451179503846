import styled from "styled-components";

const Container = styled.div`
  width: ${(props) => (props.fluid ? "100%" : "90%")};
  max-width: ${(props) => (props.small ? "900px" : "1200px")};
  margin: 0 auto;
  padding: 4rem 0;
`;

export default Container;
