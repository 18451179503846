import React from "react";
import MenuItem from "./MenuItem";
import styled from "styled-components";
import media from "styled-media-query"; //https://github.com/morajabi/styled-media-query

const MenuTitle = styled.h3`
  font-size: ${(props) => (props.small ? "1.25rem" : "1.875rem")};
  margin: 1.5625rem 0 1.25rem;
  color: #0e1428;

  ${media.greaterThan("medium")`
    font-size: ${(props) => (props.small ? "1.5625rem" : "2.1875rem")};
  `}
`;

function MenuList(props) {
  return props.categories.map((categoryItem) => (
    <React.Fragment>
      <div id={categoryItem.anchor}>
        <MenuTitle small={!categoryItem.subcategoryList}>
          {categoryItem.name}
        </MenuTitle>
        {props.menu
          .filter((filteredItem) => filteredItem.categoryId === categoryItem.id)
          .sort(function (obj1, obj2) {
            // Ascending ID order
            return obj1.id - obj2.id;
          })
          .map((item) => (
            <MenuItem
              key={item.id}
              id={item.id}
              name={item.name}
              description={item.description}
              image={item.image}
              list={item.pricelist}
            />
          ))}
      </div>
    </React.Fragment>
  ));
}

export default MenuList;
