import React, { Fragment } from "react";
import styled from "styled-components";
import media from "styled-media-query"; //https://github.com/morajabi/styled-media-query
import MenuNavigation from "./MenuNavigation";
import MenuList from "./MenuList";
import SectionTitle from "../utils/SectionTitle";
import menuData from "../../data/menu.json";
import categoriesData from "../../data/categories.json";

const MenuWrapper = styled.div`
  width: 100%;
  margin: auto;
  overflow-y: visible;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1.5625rem 0;

  ${media.lessThan("medium")`
    padding-top: 0;
  `}

  ${media.greaterThan("medium")`
    max-width: 900px;
    width: 90%;
    flex-direction: row;
    padding: 3.75rem 0;
  `}
`;

const MenuContainer = styled.div`
  width: 92%;
  margin: auto;
  overflow-y: visible;
  display: flex;
  flex-direction: column;
  max-width: 590px;
`;

function Menu(props) {
  let categoriesFound = [],
    orderedCategories = [];

  //List categories presents on menu itens

  menuData.forEach((item) => {
    if (categoriesFound.indexOf(item) === -1)
      categoriesFound.push(item.categoryId);
  });

  //List ordered category objects
  categoriesData.forEach((item) => {
    if (categoriesFound.indexOf(item.id) > -1) orderedCategories.push(item);
    if (item.subcategoryList.length) {
      item.subcategoryList
        .filter((filteredItem) => categoriesFound.indexOf(filteredItem.id) > -1)
        .forEach((subItem) => {
          //Also add parent element
          if (categoriesFound.indexOf(item.id) === -1) {
            categoriesFound.push(item.id);
            orderedCategories.push(item);
          }

          orderedCategories.push(subItem);
        });
    }
  });

  return (
    <Fragment>
      <SectionTitle>Cardápio</SectionTitle>

      <MenuWrapper>
        <MenuNavigation categories={orderedCategories}></MenuNavigation>

        <MenuContainer>
          <MenuList menu={menuData} categories={orderedCategories}></MenuList>
        </MenuContainer>
      </MenuWrapper>
    </Fragment>
  );
}

export default Menu;
