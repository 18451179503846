import React, { Fragment } from "react";
import { Title, Link, Meta } from "react-head";
import Menu from "../components/menu/Menu";
import PageHero from "../components/page-hero/PageHero";
import Container from "../components/layout/Container";
import Footer from "../components/footer/Footer";
import GlobalStyle from "../components/utils/GlobalStyle";

export default function App() {
  return (
    <Fragment>
      <Title>
        Tepanhaky Culinária Oriental - Comida Japonesa e Chinesa em Paranaguá
      </Title>
      <Meta
        name="description"
        content="A melhor comida Japonesa e Chinesa em Paranaguá há mais de 25 anos!"
      />
      <Meta
        name="keywords"
        content="sushi, sashimi, paranaguá, tepanhaky, yakisoba, delivery"
      />
      <Meta property="og:image" content="/opengraph-tepanhaky.jpg"></Meta>
      <Link rel="shortcut icon" href="/favicon.ico"></Link>
      <Link rel="preconnect" href="https://fonts.gstatic.com"></Link>

      <GlobalStyle />
      <PageHero></PageHero>
      {/* <Container small fluid>
        <Menu />
      </Container> */}
      <Footer></Footer>
    </Fragment>
  );
}
