import React from "react";
import styled from "styled-components";
import media from "styled-media-query"; //https://github.com/morajabi/styled-media-query
import LazyLoad from "react-lazyload";
import latinize from "latinize";
import NumberFormat from "react-number-format";

const MenuItemWrapper = styled.div`
  margin: 0 0 1rem;
  border-radius: 0.5rem;

  -webkit-box-shadow: 0px 11px 28px -3px rgba(189, 189, 189, 0.6);
  -moz-box-shadow: 0px 11px 28px -3px rgba(189, 189, 189, 0.6);
  box-shadow: 0px 11px 28px -3px rgba(189, 189, 189, 0.6);
  background-color: white;

  &.transparent {
    font-size: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    .content-wrapper {
      padding-top: 0.7rem;
      padding-bottom: 0.6rem;

      ${media.greaterThan("medium")`
        padding-top: 1rem;
        padding-bottom: 0.7rem;
      `}
    }
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 1.5rem 1.5625rem 1.2rem;

  .detailsWrapper {
    width: 100%;
  }

  .item-price__value {
    opacity: 0.6;
  }

  .item-price__quantity {
    text-align: right;
    width: 100%;
    color: #bdbdbd;
  }

  .item-price__value p,
  .item-price__quantity p {
    font-size: 0.895rem;
    font-weight: 600;
    line-height: 1.05rem;
    font-family: "Inter", sans-serif;
  }

  ${media.greaterThan("medium")`
    padding: 2rem 1.5625rem 1.5rem;
  `}
`;

const ImageWrapper = styled.div`
  background: linear-gradient(280.56deg, #f4f4f4 18.17%, #e5e5e5 80.85%);
  width: 100%;
  height: 140px;
  border-radius: 8px 8px 0 0;
  padding: 0;

  ${media.between("small", "medium")`
    height: 170px;
  `}

  ${media.greaterThan("medium")`
    height: 230px;
  `}
`;

const ItemName = styled.p`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.375rem;
  color: #4f4f4f;
  margin: 0 0 0.4375rem;
`;

const ItemDescription = styled.p`
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: #4f4f4f;

  margin: 0 0 0.5rem;
`;

const UnstiledList = styled.li`
  list-style: none;
  display: flex;
  flex-direction: row;
  margin-bottom: 0.4375rem;

  &:last-child {
    margin-bottom: 0;
  }

  .item-price__value {
    min-width: 5.275rem;
  }

  ${media.greaterThan("medium")`
    .item-price__value{
       min-width: 6.875rem;
    }
  `}
`;

const ItemImage = styled.img`
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 8px 8px 0 0;
`;

function ItemPrice(props) {
  return (
    <UnstiledList>
      <div className={`item-price__value`}>
        <p>
          <NumberFormat
            value={props.value}
            displayType={"text"}
            thousandSeparator={false}
            decimalSeparator={","}
            decimalScale={2}
            fixedDecimalScale={true}
            prefix={"R$ "}
          />
        </p>
      </div>
      <div className={`item-price__quantity`}>
        <p>{props.quantity}</p>
        <p>{props.description}</p>
      </div>
    </UnstiledList>
  );
}

function PriceList(props) {
  return props.list.map((item, index) => (
    <ItemPrice
      key={index}
      value={item.value}
      quantity={item.quantity}
      description={item.description}
    />
  ));
}

function MenuItem(props) {
  // const isTransparent = props.description?.length == 0 && props.image?.length == 0;
  const isTransparent = false;

  return (
    <MenuItemWrapper
      id={latinize(props.subcategory)}
      className={isTransparent ? "transparent" : ""}
    >
      {props.image && (
        <LazyLoad>
          <ImageWrapper>
            <ItemImage src={props.image} alt={props.name} />
          </ImageWrapper>
        </LazyLoad>
      )}
      <ContentWrapper className="content-wrapper">
        <div className="detailsWrapper">
          <ItemName>{props.name}</ItemName>
          <ItemDescription>{props.description}</ItemDescription>
          <PriceList list={props.list} />
        </div>
      </ContentWrapper>
    </MenuItemWrapper>
  );
}

export default MenuItem;
