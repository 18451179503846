import React from "react";
import styled from "styled-components";
import media from "styled-media-query"; //https://github.com/morajabi/styled-media-query
import SectionTitle from "../utils/SectionTitle";
import Container from "../layout/Container";

const FooterWrapper = styled.div`
  background-image: url(/background-clean.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  padding: 1.5rem 0;
  color: white;

  h4 {
    text-align: center;
  }
`;

const ButtonsWrapper = styled.div`
  margin: 2rem auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;

  a {
    flex-basis: 100%;
  }
  ${media.greaterThan("medium")`
    max-width: 600px;

    a {
      flex-basis: calc(50% - 2rem);
    }
    `}
`;

const SectionLightTitle = styled(SectionTitle)`
  color: white;
  margin-bottom: 0.9375rem;
`;

const Button = styled.button`
  display: inline-block;
  background-color: #424651;
  color: white;
  font-size: 0.875rem;
  margin: 1.4rem 1rem 0;
  padding: 0.9rem 1.5rem;
  border-radius: 25px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  text-decoration: none;
  transition: width ease-in-out 200ms;

  &.active,
  &:hover {
    color: white;
    background-color: #ba252a;
  }

  &.whatsapp {
    background-color: green;
    color: white;

    &.active,
    &:hover {
      color: green;
      background-color: white;
    }
  }

  ${media.greaterThan("medium")`
    margin-bottom: 1.4rem;
  `}
`;

function Footer(props) {
  return (
    <FooterWrapper>
      <Container>
        <SectionLightTitle>Faça seu pedido!</SectionLightTitle>
        <h4>De Segunda a Sábado 18h30 até 23h30</h4>

        <ButtonsWrapper>
          <Button
            as="a"
            href="https://api.whatsapp.com/send?phone=5541991530163&text=Ol%C3%A1%2C%20eu%20gostaria%20de%20fazer%20um%20pedido!"
            className="whatsapp"
          >
            WhatsApp (41) 99153-0163
          </Button>
          <Button as="a" href="tel:04134234102">
            Telefone: (41) 3423-4102
          </Button>
        </ButtonsWrapper>

        <h4>
          Endereço: Alameda Coronel Elísio Pereira, 304 - Estradinha, Paranaguá
          - PR
        </h4>
      </Container>
    </FooterWrapper>
  );
}

export default Footer;
