import React, { useState, useEffect } from "react";
import styled from "styled-components";
import media, { defaultBreakpoints } from "styled-media-query"; //https://github.com/morajabi/styled-media-query
import AnchorLink from "react-anchor-link-smooth-scroll";
import Scrollspy from "react-scrollspy";
import { debounce } from "throttle-debounce";
import scrollIntoView from "scroll-into-view-if-needed";

const NavigationBar = styled.nav`
  position: sticky;
  z-index: 1;
  top: -1px;
  width: 100%;

  ul {
    box-sizing: border-box;
    list-style: none;
    list-style-type: none;

    li {
      margin-bottom: 0.25rem;
      position: relative;
      width: 100%;

      a {
        font-family: "Inter", sans-serif;
        display: block;
        padding: 0.3438rem 0;
        text-decoration: none;
        color: gray;
        width: 100%;
        position: relative;
      }

      &:after {
        position: absolute;
        display: block;
        content: "";
        bottom: 0;
        left: 0;
        height: 4px;
        width: 100%;
        transition: all ease-in-out 220ms;
        background-color: #ba252a;
        transform: scaleX(0);
      }

      &.active {
        &:after {
          transition-delay: 200ms;
        }
      }

      &.active,
      &:active,
      &:hover {
        &:after {
          width: 100%;
          transform: scaleX(1);
        }
      }
    }
  }

  ${media.lessThan("medium")`
    background-color:white;
    margin-bottom: 1.875rem;

    &:after {
      position: absolute;
      display: block;
      content: "";
      bottom: 0;
      left: 0;
      height: 4px;
      width: 100%;
      background-color: rgba(189,189,189,0.3);
    }

    ul{
      display: flex;
      flex-direction:row;
      width: 100%;
      flex-wrap:nowrap;
      overflow-x:scroll;
      overflow-y:visible;

      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
      &::-webkit-scrollbar {
        display: none;
      }

      li {
        padding: 0;
        margin-bottom: 0;
        
        a {
          white-space:nowrap;
          padding: 1rem 1.5rem;
        }        
      }
    }
  `}

  ${media.greaterThan("medium")`
    flex-basis: 240px;
    margin-right: 2.1875rem;

    ul {
      padding-top: 1.25rem;
      position: relative;

      li {
        margin-bottom: 0.625rem;

        &:after {
          width: 0;
          transform: scaleX(1);
        }

        &.active,
        &:active,
        &:hover {
          &:after {
            width: 100%;
          }
        }
      }
    }
  `}
`;

function MenuNavigation(props) {
  let menuItems = props.categories.map((item) => (
    <li>
      <AnchorLink offset={() => 90} href={"#" + item.anchor}>
        {item.name}
      </AnchorLink>
    </li>
  ));

  const debounceFunc = debounce(250, () => {
    let activeElm = document.querySelector("nav ul li.active a");

    if (activeElm) {
      scrollIntoView(activeElm, {
        behavior: "smooth",
        scrollMode: "if-needed",
        block: "nearest",
        inline: "center",
      });
    }
  });

  const vw = useWindowSize().width;

  const isMobile = () => {
    if (vw <= parseInt(defaultBreakpoints.medium)) return -250;
    else return -100;
  };

  return (
    <NavigationBar>
      <Scrollspy
        items={props.categories.map((item) => item.anchor)}
        currentClassName="active"
        offset={isMobile()}
        onUpdate={debounceFunc}
      >
        {menuItems}
      </Scrollspy>
    </NavigationBar>
  );
}

// Hook
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

export default MenuNavigation;
